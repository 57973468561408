import * as React from "react";
import Creatable from "react-select/creatable";
import { Link, useHistory } from "react-router-dom";
import { parse } from "date-fns";
import {
    ApiRequest,
    Contact,
    WHATA_ENDPOINTS,
    deepEqual,
    nullifyObject,
} from "@plinknz/tah-website-elements";
import {
    Address,
    AddressFinderInput,
    AddressMeta,
} from "../../../../components/form/address-finder-input";
import { UserContext } from "../../../../service/reducers/user";
import * as ROUTES from "../../../../config/router";
import { Loader } from "../../../../components/loader";
import { DatePicker } from "../../../../components/form/datepicker";
import { whataRequest } from "../../../../service/member/whata";

import { detailsReducer } from "../../../../service/reducers/update-details";
import { Form } from "../../../../components/form/form";
import { Button } from "../../../../components/button";

// TODO: This file is quite large, consider putting each form section into it's own file

interface UpdateDetailsProps {
    whata?: ApiRequest;
    fetchUser: () => void;
}

function getByType<T extends Contact>(items: T[], typeToFind: string) {
    return items?.find(({ typeId }) => typeId === typeToFind);
}

export const UpdateDetails = ({
    fetchUser,
    whata = whataRequest,
}: UpdateDetailsProps) => {
    const {
        state: { member, loading: isLoading },
        dispatch: userDispatch,
    } = React.useContext(UserContext);
    const [state, formDispatch] = React.useReducer<typeof detailsReducer>(
        detailsReducer,
        {
            title: member?.title,
            firstName: member?.firstName,
            middleNames: member?.middleNames,
            surname: member?.surname,
            gender: member?.gender,
            dob: parse(member?.dob, "yyyyMMdd", new Date()),
            email: member?.email[0]?.value,
            occupation: member?.occupation,
            phone: getByType(member?.phone, "PH")?.value || "",
            cell: getByType(member?.phone, "PM")?.value || "",
            address: {
                line1: member?.address[0]?.line1,
                line2: member?.address[0]?.line2,
                suburb: member?.address[0]?.suburb,
                city: member?.address[0]?.city,
                country: member?.address[0]?.country,
                postcode: member?.address[0]?.postcode,
            },
        }
    );

    const address = member?.address[member?.address.length - 1];

    const day = parse(member?.dob, "yyyyMMdd", new Date());
    const year = day.getFullYear();
    const month = day.getMonth();

    const history = useHistory();
    const [dateOfBirth, setDateOfBirth] = React.useState<string | null>(null);
    const [aliases, setAliases] = React.useState<string[]>(member?.aliases);

    const createOption = (label: string) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ""),
    });

    const handleAddressSelect = (
        _fullAddress: string,
        selectedAddress: AddressMeta | Address
    ) => {
        if ("address_line_1" in selectedAddress) {
            formDispatch({
                type: "set_field",
                payload: {
                    field: "address",
                    value: {
                        line1: selectedAddress.address_line_1,
                        line2: selectedAddress.address_line_2 || "",
                        suburb: selectedAddress.suburb || "",
                        city: selectedAddress.city,
                        country: selectedAddress.country,
                        postcode: selectedAddress.postcode,
                    },
                },
            });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        userDispatch({ type: "loading", isLoading: true });

        try {
            const personEndpoint = `${WHATA_ENDPOINTS.person()}/${member.id}`;
            const aliasEndpoint = `${personEndpoint}/updateAlias`;
            const changes: Promise<unknown>[] = [];
            const addressUpdate = nullifyObject({
                line1: state.address.line1,
                line2: state.address.line2,
                postcode: state.address.postcode,
                suburb: state.address.suburb,
                city: state.address.city,
                country: state.address.country,
                type: "AH",
                lock_key: address?.lastUpdated,
                do_not_contact: false,
            });

            const memberUpdate = nullifyObject({
                ...member,
                title: state.title,
                firstName: state.firstName,
                middleNames: state.middleNames,
                surname: state.surname,
                gender: state.gender,
                occupation: state.occupation,
                dob: dateOfBirth || member.dob,
            });

            if (!deepEqual(aliases, member.aliases)) {
                changes.push(
                    whata.post(aliasEndpoint, {
                        aliases,
                        aliasLastUpdated: member.AliasLastUpdated,
                    })
                );
            }

            // Address
            if (!address) {
                changes.push(
                    whata.post(`${personEndpoint}/address`, addressUpdate)
                );
            } else if (
                !deepEqual(addressUpdate, address, { ignoreMissing: true })
            ) {
                const addressEndpoint = `${personEndpoint}/address/${member.address[0].id}`;

                changes.push(whata.put(addressEndpoint, addressUpdate));
            }

            if (!deepEqual(member, memberUpdate)) {
                changes.push(whata.put(personEndpoint, memberUpdate));
            }

            await Promise.all(changes);

            fetchUser();

            history.push(ROUTES.membersDetails());
        } catch (error) {
            console.error(error);

            userDispatch({
                type: "error",
                error: "Auē, there was an error updating your information. Please reload and try again. ",
            });
        } finally {
            userDispatch({ type: "loading", isLoading: false });
        }
    };

    // Remove error messages on mount
    React.useEffect(() => {
        userDispatch({ type: "error", error: null });
    }, []);

    if (isLoading) {
        return (
            <div className="splash">
                <Loader size="large" />
            </div>
        );
    }

    if (!member) {
        return null;
    }

    return (
        <Form onSubmit={handleSubmit} data-testid="update-details">
            <Form.Section heading="Personal Information">
                <Form.Row>
                    <Form.InputGroup name="title" label="Title">
                        <select
                            name="title"
                            className="dropdown"
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "title",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                            value={state.title}>
                            <option disabled>Select an option</option>
                            <option value="Ms">Ms</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Mr">Mr</option>
                            <option value="Miss">Miss</option>
                        </select>
                    </Form.InputGroup>
                    <Form.InputGroup />
                    <Form.InputGroup />
                </Form.Row>
                <Form.Row>
                    <Form.InputGroup name="firstName" label="First name">
                        <input
                            type="text"
                            className="input"
                            placeholder="First Name"
                            name="firstName"
                            value={state.firstName}
                            required
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "firstName",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup name="middleNames" label="Middle names">
                        <input
                            type="text"
                            className="input"
                            placeholder="Middle Names"
                            name="middleNames"
                            value={state.middleNames}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "middleNames",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup name="surname" label="Surname">
                        <input
                            type="text"
                            className="input"
                            placeholder="Surname"
                            name="surname"
                            value={state.surname}
                            required
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "surname",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                </Form.Row>

                <Form.Row>
                    <Form.InputGroup name="gender" label="Gender">
                        <select
                            name="gender"
                            className="dropdown"
                            value={state.gender}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "gender",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                            required>
                            <option disabled>Select an option</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </Form.InputGroup>
                    <Form.InputGroup />
                    <Form.InputGroup />
                </Form.Row>
                <Form.Row>
                    <Form.InputGroup name="aliases" label="Aliases">
                        <Creatable
                            className="select"
                            name="aliases"
                            value={aliases.map(createOption)}
                            onChange={(newValue) =>
                                setAliases(newValue.map((value) => value.label))
                            }
                            isMulti
                        />
                    </Form.InputGroup>
                    <Form.InputGroup />
                    <Form.InputGroup />
                </Form.Row>
            </Form.Section>

            <Form.Section heading="Date of Birth">
                <DatePicker
                    onChange={(date) => setDateOfBirth(date)}
                    defaultValues={{
                        year,
                        month,
                        day,
                    }}>
                    <Form.Row>
                        <Form.InputGroup label="Year">
                            <DatePicker.Year />
                        </Form.InputGroup>
                        <Form.InputGroup label="Month">
                            <DatePicker.Month />
                        </Form.InputGroup>
                        <Form.InputGroup />
                    </Form.Row>
                    <Form.InputGroup>
                        <DatePicker.Day />
                    </Form.InputGroup>
                    <DatePicker.Result />
                </DatePicker>
            </Form.Section>

            <Form.Section heading="Contact Information">
                <Form.Row>
                    <Form.InputGroup name="email" label="Email">
                        <input
                            type="text"
                            className="input"
                            placeholder="Phone number"
                            name="email"
                            value={state.email}
                            readOnly
                            disabled
                        />

                        <Form.Note>
                            Email can not be changed since it's used for log in.
                            Please get in touch to update it.
                        </Form.Note>
                    </Form.InputGroup>
                    <Form.InputGroup />
                    <Form.InputGroup />
                </Form.Row>
                <Form.Row>
                    <Form.InputGroup name="phone" label="Phone Number">
                        <input
                            type="text"
                            className="input"
                            placeholder="Enter your phone number"
                            name="phone"
                            value={state.phone}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "phone",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup name="cell" label="Cell Phone Number">
                        <input
                            type="text"
                            className="input"
                            placeholder="Enter your cell phone number"
                            name="cell"
                            value={state.cell}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "cell",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup />
                </Form.Row>
            </Form.Section>

            <Form.Section heading="Address">
                <Form.Row>
                    <Form.InputGroup
                        name="addressfinder"
                        label="Search for address">
                        <AddressFinderInput
                            className="input"
                            placeholder="Search for an address in New Zealand..."
                            addressFinderKey={
                                process.env.ADDRESS_FINDER_KEY || ""
                            }
                            onSelected={handleAddressSelect}
                            id="addressFinder"
                        />
                    </Form.InputGroup>
                </Form.Row>
                <Form.Row>
                    <Form.InputGroup name="line1" label="Line 1">
                        <input
                            type="text"
                            className="input"
                            placeholder="Line 1"
                            name="line1"
                            value={state.address.line1 as string}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_address",
                                    payload: {
                                        field: "line1",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                            required
                        />
                    </Form.InputGroup>
                    <Form.InputGroup name="line2" label="Line 2">
                        <input
                            type="text"
                            className="input"
                            placeholder="Line 2"
                            name="line2"
                            value={state.address.line2 as string}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_address",
                                    payload: {
                                        field: "line2",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup name="suburb" label="Suburb">
                        <input
                            type="text"
                            className="input"
                            placeholder="Suburb"
                            name="suburb"
                            value={state.address.suburb as string}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_address",
                                    payload: {
                                        field: "suburb",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                </Form.Row>
                <Form.Row>
                    <Form.InputGroup name="city" label="City">
                        <input
                            type="text"
                            className="input"
                            placeholder="City"
                            name="city"
                            value={state.address.city as string}
                            required
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_address",
                                    payload: {
                                        field: "city",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup name="country" label="Country">
                        <input
                            type="text"
                            className="input"
                            placeholder="Country"
                            name="country"
                            value={state.address.country as string}
                            required
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_address",
                                    payload: {
                                        field: "country",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup name="postcode" label="Postcode">
                        <input
                            type="text"
                            className="input"
                            placeholder="Postcode"
                            name="postcode"
                            value={state.address.postcode as string}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_address",
                                    payload: {
                                        field: "postcode",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                </Form.Row>
            </Form.Section>

            <Form.Section heading="Other information">
                <Form.Row>
                    <Form.InputGroup name="occupation" label="Occupation">
                        <input
                            type="text"
                            className="input"
                            placeholder="Enter your occupation"
                            name="occupation"
                            defaultValue={member.occupation}
                            onChange={(event) =>
                                formDispatch({
                                    type: "set_field",
                                    payload: {
                                        field: "occupation",
                                        value: event.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.InputGroup>
                    <Form.InputGroup />
                    <Form.InputGroup />
                </Form.Row>
            </Form.Section>
            <Form.Actions>
                <Button variant="primary" submit>
                    Update
                </Button>
                <Link
                    to={ROUTES.membersDetails()}
                    className="button tertiary"
                    type="button">
                    Cancel
                </Link>
            </Form.Actions>
        </Form>
    );
};
