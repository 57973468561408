import { useQuery } from "@apollo/client";
import { MemberIndexPageQueryData } from "@plinknz/tah-website-elements";
import { GET_MEMBER_MAIN_PAGE, showLoad } from "@plinknz/tah-website-elements";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { Hero } from "../../../components/hero";
import { NotFound } from "../../404";
import * as ROUTES from "../../../config/router";

export const MemberIndexPage = () => {
    const { loading, data, error } =
        useQuery<MemberIndexPageQueryData>(GET_MEMBER_MAIN_PAGE);

    if (!data && showLoad(loading)) {
        return <Hero hasChildPages={false} isLoading={loading} />;
    }

    if (
        !showLoad(loading) &&
        !error &&
        !data?.memberSection?.member_only_page
    ) {
        return <NotFound />;
    }

    if (error) {
        console.warn(error);

        return (
            <Hero
                title="There was an unexpected error"
                subtitle={error?.message}
            />
        );
    }

    const main = data?.memberSection?.member_only_page;

    return <Redirect to={main?.slug || ROUTES.home()} />;
};
