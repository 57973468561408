import { makeVar, useQuery } from "@apollo/client";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { generate } from "shortid";
import { MemberPageQueryData } from "@plinknz/tah-website-elements";
import {
    GET_MEMA_PAGE,
    showLoad,
    getMemaSlug,
} from "@plinknz/tah-website-elements";
import { AsyncBlock } from "../../../components/async-block";
import { CallToActionBlock } from "../../../components/content-blocks/call-to-action-block";
import { Content } from "../../../components/content-blocks/content";
import { DownloadBlock } from "../../../components/content-blocks/download-block";
import { GalleryBlock } from "../../../components/content-blocks/gallery";
import { LinksBlock } from "../../../components/content-blocks/links-block";
import { NewsGrid } from "../../../components/content-blocks/news-grid";
import { PictureList } from "../../../components/content-blocks/picture-list";
import { Product } from "../../../components/content-blocks/product";
import { YoutubePlayerContentBlock } from "../../../components/content-blocks/youtube-player";
import { Hero } from "../../../components/hero";
import { Menu } from "../../../components/menu";
import { PageTitle } from "../../../components/page-title";

import * as ROUTES from "../../../config/router";
import { removeLeadingSlash } from "../../../utility/remove-leading-slash";
import { NotFound } from "../../404";

const NEWS_GRID_ITEMS = 6;
export const newsGridLimit = makeVar(NEWS_GRID_ITEMS);

export const MemberPage = () => {
    const location = useLocation();
    const slug = getMemaSlug(location);
    const { loading, data, error } = useQuery<MemberPageQueryData>(
        GET_MEMA_PAGE,
        {
            variables: { slug, limit: newsGridLimit() },
        }
    );

    if (!data && showLoad(loading)) {
        return (
            <Hero
                title=""
                subtitle=""
                hasChildPages={false}
                isLoading={loading}
            />
        );
    }

    if (!showLoad(loading) && !error && data?.memberOnlyPages.length === 0) {
        return <NotFound />;
    }

    if (error) {
        console.warn(error);

        return (
            <Hero
                title="There was an unexpected error"
                subtitle={error?.networkError?.message}
            />
        );
    }

    const page = data?.memberOnlyPages[0];

    if (!page || data?.memberOnlyPages.length > 1) {
        return <NotFound />;
    }

    const showChildPages = page?.show_children_pages;

    return (
        <>
            <Hero
                title={page.title}
                subtitle={page.subtitle}
                image={page.cover_image}
                hasChildPages={
                    (showChildPages && page.children.length > 0) ||
                    !!page.parent
                }
            />
            <PageTitle
                title={page.title}
                showSuffix={slug !== removeLeadingSlash(ROUTES.home())}
            />
            <Menu
                links={page.children}
                parent={page.parent}
                currentPage={page}
                show={!!showChildPages}
            />
            <section>
                {page.content.map((block) => {
                    const key = generate();

                    // eslint-disable-next-line no-underscore-dangle
                    switch (block.__typename) {
                        case "ComponentContentBlocksContent":
                            return <Content key={key} data={block} />;
                        case "ComponentMemberPageGrid":
                        case "ComponentContentBlocksPanuiGrid":
                            return <NewsGrid key={key} data={block} />;
                        case "ComponentContentBlocksProfileSet":
                            return <PictureList key={key} data={block} />;
                        case "ComponentContentBlocksDownloadFiles":
                            return <DownloadBlock key={key} data={block} />;
                        case "ComponentContentBlocksLinks":
                            return <LinksBlock key={key} data={block} />;
                        case "ComponentContentBlocksImageGallery":
                            return <GalleryBlock key={key} data={block} />;
                        case "ComponentContentBlocksCallToAction":
                            return <CallToActionBlock key={key} data={block} />;
                        case "ComponentContentBlocksBuyProduct":
                            return <Product key={key} data={block} />;
                        case "ComponentContentBlocksYoutubePlayer":
                            return (
                                <YoutubePlayerContentBlock
                                    key={key}
                                    data={block}
                                />
                            );
                        case "ComponentContentBlocksRegistrationForm":
                            return (
                                <AsyncBlock
                                    key={key}
                                    name="registration-form"
                                    namedExport="RegistrationForm"
                                    data={block}
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </section>
        </>
    );
};
